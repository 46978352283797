







import { Vue, Component, Prop } from 'vue-property-decorator'
import ShipmentStatusSelect from '@/components/Shipment/StatusSelect.vue';
import Activity from '@/models/Activity';

@Component({components: {ShipmentStatusSelect}})
export default class UpdateShipmentStatus extends Vue {

  @Prop({required: true}) readonly activity!: Activity;

  private item: Activity = this.activity;

  get selected() {
    return this.item.meta.shipmentStatus;
  }
  onSelected(data: any) {
    this.$emit('input',data);
  }

}
