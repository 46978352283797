











import Tribute from "tributejs";
import Throttler from "@/utils/Throttler";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { TributeParts } from "@/components/Activity/UpdateSendEmail.vue";

export interface IFormulaOptions {
  key: string;
  value: string;
  label: string;
  id?: string;
}

@Component({})
export default class VFormula extends Vue {
  @Prop({ required: true }) readonly values!: IFormulaOptions[];
  @Prop() readonly editableString?: TributeParts[];
  private tribute: Tribute<IFormulaOptions> | null = null;
  private readonly throttler: Throttler = new Throttler();
  private showSpan = true;

  @Watch("editableString")
  async onEditableStringChanged(newVal: string, oldVal: string) {
    this.showSpan = false;
    await this.$nextTick();
    this.showSpan = true;
    await this.$nextTick();
    this.tribute!.attach(this.$el);
  }

  initTribute() {
    this.tribute = new Tribute({
      values: this.values,
      trigger: "@",
      autocompleteMode: false,
      requireLeadingSpace: false,
      noMatchTemplate: () => "",
      selectTemplate: function(item: { original: IFormulaOptions }) {
        if (typeof item === "undefined") return null;
        if (this.range.isContentEditable(this.current.element)) {
          const template = `<p class="badge badge-primary mb-0 text-lowercase">${ item?.original.id || item?.original.key }</p>`;
          return (
            `<span contenteditable="false"> ${template} </span>`
          );
        }

        return item.original.value;
      } as any,
      menuItemTemplate: item => {
        return `<div>${item.original.label}</div>`;
      }
    });
    this.tribute.attach(this.$el);
  }

  itemTemplate(item: any) {
    return `<p class="badge badge-primary mb-0 text-lowercase">${ item?.id || item?.key }</p>`;
  }

  detach() {
    this.tribute!.detach(this.$el);
  }

  mounted() {
    this.initTribute();
  }

  emitValue() {
    this.throttler.run(() => this.$emit("updated", (this.$el as any).innerText));
  }

  getInnerText() {
    return (this.$el as any).innerText;
  }

  private emitEdited() {
    this.$emit('edited', this.getInnerText());
  }
}
