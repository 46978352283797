























































































import { Order, Source } from "@/models";
import CreateModal from "@/components/Activity/Create.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import Search from "@/components/VEntrySearch.vue";
import ActivityItem from "@/components/Activity/Item.vue";
// import EditModal from "@/components/Activity/Edit.vue";
import {
  Vue,
  Component,
  Prop,
  Mixins,
  Watch,
  Ref
} from "vue-property-decorator";
import { namespace } from "vuex-class";
import ActivitiesMixin from "@/mixins/http/ActivitiesMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import { Activity } from "@/models";
import EmptyView from "@/components/EmptyView.vue";
import { easync } from "@/utils/http";
import { showToast } from "@/utils/Toast";
import { sourceStore } from "@/store/typed";

const getTag = "get_activities";
const destroyTag = "destroy_activity";

@Component({
  components: {
    // EditModal,
    ActivityItem,
    EmptyView,
    Search,
    VPageContentSticky,
    DataTable,
    CreateModal,
    ConfirmModal
  }
})
export default class ActivityList extends Mixins(ActivitiesMixin) {
  @RegisterHttp(getTag) getRequest: RequestData;
  @RegisterHttp(destroyTag) destroyRequest: RequestData;
  @Ref() createModal: HTMLDivElement;
  @Ref() editModal: HTMLDivElement;
  @Ref() confirmModal: HTMLDivElement;

  items: Activity[] = [];
  page = 1;

  columns = [
    { name: "name", label: "Nome" },
    { name: "status", label: "Stato" },
    { name: "actions", label: "" }
  ];

  search: string | null = null;

  created() {
    this.subscribe();
  }

  onCreated(item: Activity) {
    this.items.unshift(item);
  }

  onUpdated(item: Activity) {
    const index = this.items.findIndex(o => item.id === o.id);
    Vue.set(this.items, index, item);
  }

  async searchItems(name: string) {
    if (name.trim().length > 0) {
      this.search = name;
      const items = await this.getActivities(this.payload, getTag);
      return items;
    }
  }

  onSearchCanceled() {
    this.page = 1;
    this.search = null;
    this.loadItems();
  }

  onSearched(items: {items: Activity[]}) {
    this.page = 1;
    this.items = items.items;
  }

  onDeleted(id: string) {
    this.items = this.items.filter(o => id !== o.id);
  }

  openCreateModal() {
    (this.createModal as any).create();
  }

  openEditModal(item: Activity) {
    (this.createModal as any).edit(item);

  }

  async openConfirmModal(item: Activity) {
    const [data, errors] = await easync(
      (this.confirmModal as any).confirm("Sei sicuro di eliminare quest'azione?")
    );

    if (errors) {
      showToast("Si è verificato un errore");
      return;
    }

    if (data) {
      this.destroyItem(item.id);
    }
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  async destroyItem(id: string) {
    const [data, errors] = await easync(this.destroyActivity(id, destroyTag));
    if (errors) {
      showToast("Si è verificato un errore");
      return;
    }

    this.onDeleted(id);
  }

  mounted() {
    if (sourceStore.currentSource) {
      this.loadItems();
    }
  }

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    if (source) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  get payload() {
    const data = {
      per: 20,
      include: ["name"],
      page: this.page,
      filter: {
        source: sourceStore.currentSource?.id,
        term: this.search
      }
    };

    return data;
  }

  async loadItems() {
    const [data, errors] = await easync(this.getActivities(this.payload, getTag));
    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...data);
  }

  get loading() {
    return this.getRequest?.loading;
  }

  get meta() {
    return this.getRequest?.meta;
  }
}
