

















































import { Component, Prop, Vue, Mixins, Ref } from "vue-property-decorator";
import VModal from "@/components/VModal.vue";
import ActivitiesMixin from "@/mixins/http/ActivitiesMixin";
import UpdateSendEmail from "@/components/Activity/UpdateSendEmail.vue";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import { Activity } from "@/models";
import CarrierSelect from "@/components/Carrier/Select.vue";
import ShippingMethodSelect from "@/components/ShippingMethod/Select.vue";
import { namespace } from "vuex-class";
import { Source } from "@/models";
import ActivityTypeSelect from "@/components/Activity/TypeSelect.vue";
import { showToast } from "@/utils/Toast";
import UpdateOrderStatus from "@/components/Activity/UpdateOrderStatus.vue";
import UpdateShipmentStatus from "@/components/Activity/UpdateShipmentStatus.vue";

import { NoCache } from "@/utils/Decorators";
import { Shipment } from "@/models";
import { easync } from "@/utils/http";
import { sourceStore } from "@/store/typed";
const createRequestTag = "create_request_tag";

@Component({
  components: {
    VModal,
    UpdateOrderStatus,
    UpdateShipmentStatus,
    ActivityTypeSelect,
    CarrierSelect,
    ShippingMethodSelect,
    UpdateSendEmail,
  },
})
export default class ActivityCreateModal extends Mixins(ActivitiesMixin) {
  @RegisterHttp(createRequestTag) createRequest: RequestData;
  modal!: VModal;
  content = "";

  item: Activity = null;

  canceled!: any | null;

  @Ref() readonly updateSendEmail!: UpdateSendEmail;

  mounted() {
    this.modal = this.$refs.modal as VModal;
  }

  loading() {
    return this.createRequest?.loading;
  }

  @NoCache
  get updateOrderStatus() {
    return this.item.type === "Activity::UpdateOrderStatus";
  }

  @NoCache
  get updateShipmentStatus() {
    return this.item.type === "Activity::UpdateShipmentStatus";
  }

   @NoCache
  get sendEmail() {
    return this.item.type === "Activity::SendEmail";
  }

  onTypeSelected(object: Activity) {
    this.$nextTick(() => {
      this.item.type = object.type;
    });
  }

  onOrderStatusSelected(orderStatus: any) {
    this.item.meta.orderStatusId = orderStatus.id;
    this.item.name = `Aggiornamento ordine a ${orderStatus.name}`;
  }

  onShipmentStatusSelected(shipmentStatus: any) {
    this.item.meta.shipmentStatus = shipmentStatus
    const name = Shipment.statusLabel(shipmentStatus)
    this.item.name = `Aggiornamento spedizione a ${name}`;
  }

  onSendEmailUpdated(meta: any) {
    this.item.meta = meta;
    this.item.name = 'Email';
  }

  get valid() {
    return this.item?.type && this.item?.name;
  }

  title() {
    return `Nuova azione`;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
    this.reset();
  }

  edit(activity: Activity) {
    this.item = activity;
    this.modal.show();
  }

  create() {
    this.item = Object.assign({ type: null, name: "", meta: {} });
    this.modal.show();
  }

  save() {
    this.item.id ? this.updateAction() : this.createAction();
  }

  async createAction() {
    const [data, errors] = await easync(
      this.createActivity({ ...this.item, sourceId: sourceStore.currentSource.id }, createRequestTag)
    );

    if (errors) {
      showToast("Errore durante la creazione", { type: "error" });
      return;
    }

    this.$emit("success", data);
    this.modal.hide();
    showToast("Azione creata!");
    this.reset();
  }

  async updateAction() {
    const [data, errors] = await easync(
      this.updateActivity(this.item.id, { ...this.item, sourceId: sourceStore.currentSource.id }, createRequestTag)
    );

    if (errors) {
      showToast("Errore durante la creazione", { type: "error" });
      return;
    }

    this.modal.hide();
    showToast("Azione creata!");
    this.reset();
  }

  protected onConfirm() {
    if (this.sendEmail) {
      const emailMeta = this.updateSendEmail.getData();
      this.item.meta = emailMeta;
    }
    this.save();
  }

  protected onHidden() {
    if (this.canceled) {
      this.canceled();
    }
  }

  reset() {
    this.item = new Activity(undefined);
  }

  show() {
    this.modal.show();
  }
}
