



















































import {
  Vue,
  Component,
  Prop,
  Watch,
  Mixins,
  Ref,
} from "vue-property-decorator";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import VTribute, { IFormulaOptions } from "@/components/VTribute.vue";
import Throttler from "@/utils/Throttler";
import { Order, SendEmail } from "@/models";

const tag = "validate_tribute";

export interface TributeParts {
  value: string;
  data?: IFormulaOptions;
  tribute?: boolean;
}
@Component({ components: { VTribute } })
export default class UpdateSendEmail extends Mixins() {
  @RegisterHttp(tag) validateFormulaRequest: RequestData;
  @Ref("tag_input") readonly input!: HTMLInputElement;
  @Prop() readonly activity: SendEmail;

  private values?: any = null;
  private throttler = new Throttler();

  @Ref() readonly subjectTribute!: VTribute;
  @Ref() readonly introTribute!: VTribute;
  @Ref() readonly contentTribute!: VTribute;
  @Ref() readonly signatureTribute!: VTribute;

  get items() {
    return Order.selectableColumns.map((o) => {
      return {
        key: o.id,
        value: o.id,
        label: o.label,
      };
    });
  }

  get editableContentString() {
    return this.getParts(this.activity.meta.content as string);
  }

  get editableSubjectString() {
    return this.getParts(this.activity.meta.subject as string);
  }

  get editableIntroString() {
    return this.getParts(this.activity.meta.intro as string);
  }

   get editableSignatureString() {
    return this.getParts(this.activity.meta.signature as string);
  }

  getParts(meta: string) {

    const parts: TributeParts[] = [];
    const editableArray = meta?.split(" ") || [];

    for (const chunk of editableArray) {
      if (chunk.startsWith('@@') && chunk.endsWith('@@')) {
        const column = chunk.replace("@@", "").replace("@@", "").replace('.','');
        const data = this.items.find((o) => (o as any).key === column);
        parts.push({ value: column, data, tribute: true });
      } else {
        parts.push({ value: chunk });
      }
    }
    return parts;
  }

  async onContentUpdated(expression: string) {
    this.$emit(
      "updated",
      Object.assign(this.activity.meta, { content: this.cleanExpression(expression) })
    );
  }

  async onSubjectUpdated(expression: string) {
    this.$emit(
      "updated",
      Object.assign(this.activity.meta, { subject: this.cleanExpression(expression) })
    );
  }

  async onSignatureUpdated(expression: string) {
    this.$emit(
      "updated",
      Object.assign(this.activity.meta, { signature: this.cleanExpression(expression) })
    );
  }

  async onIntroUpdated(expression: string){
    this.$emit(
      "updated",
      Object.assign(this.activity.meta, { intro: this.cleanExpression(expression) })
    );
  }

  cleanExpression(expression: string) {
     if (expression?.length == 0) {
      return '';
    }
    for (let column of Order.selectableColumns) {
      const re = new RegExp(column.id,"g");
      expression = expression.replace(re, `@@${column.id}@@`);
    }

    expression = expression
      .trim()
      .replace(/\xa0/g, " ")
      .replace(/\n\n+/g, ' ')
      .replace(/\s\s+/g, ' ');
     return expression;

  }

  async mounted() {
    await this.$nextTick();
    this.values = Order.selectableColumns;
  }

  onTagChanged(input: any) {
    const value = input.target.value;
  }

  getData() {
    const meta = {...this.activity.meta};
    meta.content = this.cleanExpression(this.contentTribute.getInnerText());
    meta.subject = this.cleanExpression(this.subjectTribute.getInnerText());
    meta.intro = this.cleanExpression(this.introTribute.getInnerText());
    meta.signature = this.cleanExpression(this.signatureTribute.getInnerText());

    return meta;
  }
}
